import React from "react";
import { CustomSpinner } from "../../../components/Loader/custom.spinner";
import { DEFAULT_SNACKBAR } from "../../../utils/snackbar";
import error from "../../../utils/data.json";
import { Snackbar } from "../../../components/Snackbar";
import { useNavigate } from "react-router-dom";
import { THEME_SEVERITY } from "../../../utils/theme";

export const SenceErrorLogin = () => {
  const [snackBar, setSnackBar] = React.useState(DEFAULT_SNACKBAR);
  const navigate = useNavigate();

  React.useEffect(() => {
    let URL_error = window.location.href.slice(-3);
    let error_description = "";
    for (let i = 0; i < error.error.length - 1; i++) {
      if (URL_error === error.error[i].value) {
        error_description = error.error[i].description;
      }
    }
    const ERROR_LOG = {
      visible: true,
      message: `${error_description}`,
      severity: THEME_SEVERITY.ERROR,
    };
    setSnackBar(ERROR_LOG);
    setTimeout(() => {
      setSnackBar(DEFAULT_SNACKBAR);
      navigate("/listcourses");
    }, 10000);
    // eslint-disable-next-line
  }, []);

  //posible solucion, crear una ruta que redirija a liscourses, pero que reciba :id para poder desplegar snackbar

  return (
    <>
      <Snackbar
        severity={snackBar.severity}
        visible={snackBar.visible}
        message={snackBar.message}
      />
      <CustomSpinner />
    </>
  );
};

export const SenceErrorLogout = () => {
  const [snackBar, setSnackBar] = React.useState(DEFAULT_SNACKBAR);
  const navigate = useNavigate();

  React.useEffect(() => {
    let URL_error = window.location.href.slice(-3);
    let error_description = "";
    for (let i = 0; i < error.error.length - 1; i++) {
      if (URL_error === error.error[i].value) {
        error_description = error.error[i].description;
      }
    }
    const ERROR_LOG = {
      visible: true,
      message: `${error_description}`,
      severity: THEME_SEVERITY.ERROR,
    };
    setSnackBar(ERROR_LOG);
    setTimeout(() => {
      setSnackBar(DEFAULT_SNACKBAR);
      navigate("/listcourses");
    }, 10000);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Snackbar
        severity={snackBar.severity}
        visible={snackBar.visible}
        message={snackBar.message}
      />
      <CustomSpinner />
    </>
  );
};
